exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-tsx": () => import("./../../../src/pages/a-propos.tsx" /* webpackChunkName: "component---src-pages-a-propos-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-enseignement-tsx": () => import("./../../../src/pages/enseignement.tsx" /* webpackChunkName: "component---src-pages-enseignement-tsx" */),
  "component---src-pages-galerie-tsx": () => import("./../../../src/pages/galerie.tsx" /* webpackChunkName: "component---src-pages-galerie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projets-tsx": () => import("./../../../src/pages/projets.tsx" /* webpackChunkName: "component---src-pages-projets-tsx" */)
}

